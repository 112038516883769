<template>
  <div class="artificial">
    <div class="banner">
      <div class="banner_text">
        <h6 class="banner_title">临床人工智能</h6>
        <div class="line"></div>
        <p class="banner_content">CLINICAL ARTIFICIAL INTELLIGENCE</p>
      </div>
    </div>
    <div class="con">
      <div class="container">
        <div class="top">
          <h6 class="top_text">INTELLIGENT DIAGNOSIS OF RENAL PATHOLOGY</h6>
          <p class="top_content">肾脏病理智能诊断</p>
          <div class="top_line"></div>
        </div>
        <div class="img">
          <img class="imgBig" src="../assets/artificialImages/img222.jpg">
          <img class="imgSmall" src="../assets/artificialImages/img1.jpg">
        </div>
      </div>
    </div>
    <div class="show">
      <div class="container">
        <div class="top">
          <h6 class="top_text">INTELLIGENT DIAGNOSIS OF ORAL CURVED TOMOGRAPHY</h6>
          <p class="top_content">口腔曲面断层片智能诊断</p>
          <div class="top_line"></div>
        </div>
      </div>
      <div class="container">
        <el-carousel :arrow="true" :indicator-position="'outside'" :autoplay="true" :interval="2000">
          <el-carousel-item>
            <el-row type="flex" justify="center" align="middle">
              <el-col :span="24" :sm="12">
                <div class="grid-content bg-purple"><img src="../assets/artificialImages/tooth1.jpg" alt=""></div>
              </el-col>
            </el-row>
          </el-carousel-item>
          <el-carousel-item>
            <el-row type="flex" justify="center" align="middle">
              <el-col :span="24" :sm="12">
                <div class="grid-content bg-purple-light"><img src="../assets/artificialImages/tooth4.png" alt=""></div>
              </el-col>
            </el-row>
          </el-carousel-item>
          <el-carousel-item>
            <el-row type="flex" justify="center" align="middle">
              <el-col :span="24" :sm="12">
                <div class="grid-content bg-purple"><img src="../assets/artificialImages/tooth2.jpg" alt=""></div>
              </el-col>
            </el-row>
          </el-carousel-item>
          <el-carousel-item>
            <el-row type="flex" justify="center" align="middle">
              <el-col :span="24" :sm="12">
                <div class="grid-content bg-purple-light"><img src="../assets/artificialImages/tooth3.png" alt=""></div>
              </el-col>
            </el-row>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="show_bottom" >
        <div class="container"> <!-- 添加的 container 类，用于居中和限制最大宽度 -->
          <div class="row">
            <!-- 右侧文字列，原先注释有误，应为左侧文字列，现调整为col-md-6表示在中等屏幕及以上宽度占据6列 -->
            <div class="col-md-6 col-sm-12 text-left">
              <div class="text">
                <p style="font-size: 30px;">独立阿里云服务器，数据安全保障</p>
                <p style="font-size:24px;color: #999999;">Independent Alibaba Cloud servers provide data security</p>
                <p style="font-size: 20px;">(国家权威ISO27000信息安全认证)</p>
                <p style="font-size:16px;">
                  阿里云防火墙及SSL数据传输加密，最新的病毒和入侵防护，服务器监控，数据加密和物理安全性确保您的临床数据受到最高安全级别的保护。
                </p>
              </div>
            </div>
            <!-- 左侧图片列，原先注释有误，应为右侧图片列，现调整为col-md-6表示在中等屏幕及以上宽度占据6列 -->
            <div class="col-md-6 col-sm-12">
              <img src="../assets/artificialImages/tooth5.jpg" alt="Your Image" class="img-fluid">
            </div>
          </div>
        </div>
      </div>



    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      carouselImages: [
        { id: 1, src: "../assets/artificialImages/tooth1.jpg", alt: "Image 1", class: "bg-purple" },
        { id: 2, src: "../assets/artificialImages/tooth4.png", alt: "Image 2", class: "bg-purple-light" },
        { id: 3, src: "../assets/artificialImages/tooth2.jpg", alt: "Image 3", class: "bg-purple" },
        { id: 4, src: "../assets/artificialImages/tooth3.png", alt: "Image 4", class: "bg-purple-light" },
      ],
    };
  },
};
</script>
<style scoped lang="less">
/deep/ .is-active .el-carousel__button {
  background: #14A5FE;
  opacity: 1;
}

.grid-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-purple {
  background: #99a9bf;
}

.bg-purple-light {
  background: #c0c4cc;
}

/* 移动端 */
@media screen and (max-width: 767px) {
  .banner {
    width: 100%;
    height: 320px;
    background-image: url(../assets/artificialImages/Artificial_bannerMobile.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .banner_text {
      width: 385px;
      height: 200px;
      text-align: center;

      .banner_title {
        font-size: 48px;
      }

      .banner_content {
        font-size: 20px;
        color: #333333
      }

      .line {
        position: relative;
        top: 2%;
        left: 44%;
        width: 30px;
        height: 2px;
        border-radius: 0px;
        opacity: 1;
        border: 1px solid black;
        margin: 24px 0;
      }
    }
  }

  .con {
    padding-top: 20px;
    background-color: #F2F4F5;
    text-align: center;

    .top {
      .top_line {
        width: 22px;
        height: 2px;
        border-radius: 0px;
        opacity: 1;
        border: 1px solid black;
        margin: 24px auto;
      }

      .top_text,
      .top_content {
        font-size: 22px;
        color: #010101;
      }
    }

    .img {
      display: flex;
      flex-wrap: nowrap;
      margin: auto;

      .imgBig,
      .imgSmall {
        border: #010101 1px dashed;
        margin-right: 20px;
      }

      .imgBig {
        width: 205px;
        height: 195px;
      }

      .imgSmall {
        width: 120px;
        height: 120px;
        margin-top: 45px;
      }
    }

    .el-carousel__arrow {
      display: block;
    }

    .el-carousel__arrow:hover {
      color: blue !important;
    }

    .el-carousel {
      height: 548px;
      width: 0;
      /* Ensure you set a valid width here */
      border: 1px dashed black;
    }
  }

  .show {
    width: 100%;
    height: auto;
    text-align: center;
    padding-top: 20px;

    .top_line {
      position: relative;
      top: 2%;
      left: 50%;
      width: 22px;
      height: 2px;
      border-radius: 0px;
      opacity: 1;
      border: 1px solid black;
      margin: 24px 0;
    }

    .top_text,
    .top_content {
      font-size: 22px;
      color: #010101;
    }

    .text {
      text-align: center;
    }
    .show_bottom{
      background-color: #F7FBFE;
      height: 582px;
    }
  }
}

/* PC端样式 */
@media screen and (min-width: 768px) {
  .el-col {
    margin-bottom: 30px;
  }

  .banner {
    width: 100%;
    height: 600px;
    background-image: url(../assets/artificialImages/Artificial_banner.png);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .banner_text {
      width: 385px;
      height: 200px;
      text-align: center;

      .banner_title {
        font-size: 48px;
      }

      .banner_content {
        font-size: 20px;
        color: #333333;
      }

      .line {
        position: relative;
        top: 2%;
        left: 44%;
        width: 30px;
        height: 2px;
        border-radius: 0px;
        opacity: 1;
        border: 1px solid black;
        margin: 24px 0;
      }
    }
  }

  .con {
    padding-top: 20px;
    background-color: #F2F4F5;
    text-align: center;

    .top {
      .top_line {
        width: 25px;
        height: 2px;
        border-radius: 0px;
        opacity: 1;
        border: 1px solid black;
        margin: 24px auto;
      }

      .top_text,
      .top_content {
        font-size: 36px;
        color: #010101;
      }
    }

    .img {
      display: flex;
      justify-content: space-between;

      .imgBig {
        width: 676px;
        height: 471px;
      }

      .imgSmall {
        width: 345px;
        height: 309px;
        margin-top: 75px;
      }

      .el-carousel {
        height: 400px;
        width: 100%;
      }
    }
  }

  .show {
    width: 100%;
    height: 950px;
    text-align: center;
    padding-top: 20px;

    .top_line {
      position: relative;
      top: 2%;
      left: 48%;
      width: 30px;
      height: 2px;
      border-radius: 0px;
      opacity: 1;
      border: 1px solid black;
      margin: 24px 0;
    }

    .top_text,
    .top_content {
      font-size: 30px;
      color: #010101;
    }

    .show_bottom{
      padding-top: 50px;
      background-color: #F7FBFE;
      height: 458px;
    }
  }
}
</style>
